var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  exports = function (list) {
    return function (initValues, element, notCreate) {
      var item = this || _global;
      (this || _global)._values = {};
      (this || _global).found = false; // Show if list.searched == true and this.found == true

      (this || _global).filtered = false; // Show if list.filtered == true and this.filtered == true

      var init = function (initValues, element, notCreate) {
        if (element === undefined) {
          if (notCreate) {
            item.values(initValues, notCreate);
          } else {
            item.values(initValues);
          }
        } else {
          item.elm = element;
          var values = list.templater.get(item, initValues);
          item.values(values);
        }
      };

      (this || _global).values = function (newValues, notCreate) {
        if (newValues !== undefined) {
          for (var name in newValues) {
            item._values[name] = newValues[name];
          }

          if (notCreate !== true) {
            list.templater.set(item, item.values());
          }
        } else {
          return item._values;
        }
      };

      (this || _global).show = function () {
        list.templater.show(item);
      };

      (this || _global).hide = function () {
        list.templater.hide(item);
      };

      (this || _global).matching = function () {
        return list.filtered && list.searched && item.found && item.filtered || list.filtered && !list.searched && item.filtered || !list.filtered && list.searched && item.found || !list.filtered && !list.searched;
      };

      (this || _global).visible = function () {
        return item.elm && item.elm.parentNode == list.list ? true : false;
      };

      init(initValues, element, notCreate);
    };
  };

  return exports;
}