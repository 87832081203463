import { dew as _indexOfDew } from "./index-of";
var exports = {},
    _dewExec = false;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  /**
   * Module dependencies.
   */
  var index = _indexOfDew();
  /**
   * Whitespace regexp.
   */


  var re = /\s+/;
  /**
   * toString reference.
   */

  var toString = Object.prototype.toString;
  /**
   * Wrap `el` in a `ClassList`.
   *
   * @param {Element} el
   * @return {ClassList}
   * @api public
   */

  exports = function (el) {
    return new ClassList(el);
  };
  /**
   * Initialize a new ClassList for `el`.
   *
   * @param {Element} el
   * @api private
   */


  function ClassList(el) {
    if (!el || !el.nodeType) {
      throw new Error("A DOM element reference is required");
    }

    (this || _global).el = el;
    (this || _global).list = el.classList;
  }
  /**
   * Add class `name` if not already present.
   *
   * @param {String} name
   * @return {ClassList}
   * @api public
   */


  ClassList.prototype.add = function (name) {
    // classList
    if ((this || _global).list) {
      (this || _global).list.add(name);

      return this || _global;
    } // fallback


    var arr = this.array();
    var i = index(arr, name);
    if (!~i) arr.push(name);
    (this || _global).el.className = arr.join(" ");
    return this || _global;
  };
  /**
   * Remove class `name` when present, or
   * pass a regular expression to remove
   * any which match.
   *
   * @param {String|RegExp} name
   * @return {ClassList}
   * @api public
   */


  ClassList.prototype.remove = function (name) {
    // classList
    if ((this || _global).list) {
      (this || _global).list.remove(name);

      return this || _global;
    } // fallback


    var arr = this.array();
    var i = index(arr, name);
    if (~i) arr.splice(i, 1);
    (this || _global).el.className = arr.join(" ");
    return this || _global;
  };
  /**
   * Toggle class `name`, can force state via `force`.
   *
   * For browsers that support classList, but do not support `force` yet,
   * the mistake will be detected and corrected.
   *
   * @param {String} name
   * @param {Boolean} force
   * @return {ClassList}
   * @api public
   */


  ClassList.prototype.toggle = function (name, force) {
    // classList
    if ((this || _global).list) {
      if ("undefined" !== typeof force) {
        if (force !== (this || _global).list.toggle(name, force)) {
          (this || _global).list.toggle(name); // toggle again to correct

        }
      } else {
        (this || _global).list.toggle(name);
      }

      return this || _global;
    } // fallback


    if ("undefined" !== typeof force) {
      if (!force) {
        this.remove(name);
      } else {
        this.add(name);
      }
    } else {
      if (this.has(name)) {
        this.remove(name);
      } else {
        this.add(name);
      }
    }

    return this || _global;
  };
  /**
   * Return an array of classes.
   *
   * @return {Array}
   * @api public
   */


  ClassList.prototype.array = function () {
    var className = (this || _global).el.getAttribute("class") || "";
    var str = className.replace(/^\s+|\s+$/g, "");
    var arr = str.split(re);
    if ("" === arr[0]) arr.shift();
    return arr;
  };
  /**
   * Check if class `name` is present.
   *
   * @param {String} name
   * @return {ClassList}
   * @api public
   */


  ClassList.prototype.has = ClassList.prototype.contains = function (name) {
    return (this || _global).list ? (this || _global).list.contains(name) : !!~index(this.array(), name);
  };

  return exports;
}